<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Open Ticket</h3>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="f-w-600" for="type_ticket"
                    >Jenis Maintenance
                  </label>
                  <div>
                    <div class="radio radio-css radio-inline">
                      <input
                        type="radio"
                        value="Task List"
                        v-model="form.type_ticket"
                        id="radioE"
                      />
                      <label for="radioE">Task List</label>
                    </div>
                    <div class="radio radio-css radio-inline">
                      <input
                        type="radio"
                        value="Corrective"
                        v-model="form.type_ticket"
                        id="radioN"
                      />
                      <label for="radioN">Corrective Maintenance</label>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-md-3"
                :class="{
                  'col-md-4':
                  form.type_ticket == 'Task List' || form.type_ticket == 'Corrective' || form.type_ticket == '',
                }"
              >
                <div class="form-group">
                  <label class="f-w-600" for="id_area">Area </label>
                  <select
                    v-model="form.id_area"
                    type="text"
                    class="form-control"
                    required
                    @change="
                      formValidate.id_area = '';
                      getLokasi();
                    "
                    :class="{
                      'is-valid': form.id_area != '',
                      'is-invalid': formValidate.id_area || form.id_area == '',
                    }"
                  >
                    <option value="" disabled>-Pilih-</option>
                    <option
                      v-for="area in listArea"
                      :key="area.id_area"
                      :value="area.id_area"
                    >
                      {{ area.nama_area }}
                    </option>
                  </select>
                </div>
              </div>
              <div
                class="col-md-3"
                :class="{
                  'col-md-4':
                    form.type_ticket == 'Task List' || form.type_ticket == 'Corrective' || form.type_ticket == '',
                }"
              >
                <div class="form-group">
                  <label class="f-w-600" for="id_lokasi">Lokasi </label>
                  <select
                    v-model="form.id_lokasi"
                    type="text"
                    class="form-control"
                    required
                    @change="
                      formValidate.id_lokasi = '';
                      getSubLokasi();
                    "
                    :class="{
                      'is-valid': form.id_lokasi != '',
                      'is-invalid':
                        formValidate.id_lokasi || form.id_lokasi == '',
                    }"
                  >
                    <option value="" disabled>-Pilih-</option>
                    <option
                      v-for="lokasi in listLokasi"
                      :key="lokasi.id_lokasi"
                      :value="lokasi.id_lokasi"
                    >
                      {{ lokasi.nama_lokasi }}
                    </option>
                  </select>
                </div>
              </div>
              <div
                class="col-md-3"
                :class="{
                  'col-md-4':
                    form.type_ticket == 'Task List' || form.type_ticket == 'Corrective' || form.type_ticket == '',
                }"
              >
                <div class="form-group">
                  <label class="f-w-600" for="id_sublokasi">Sub Lokasi </label>
                  <select
                    v-model="form.id_sublokasi"
                    type="text"
                    class="form-control"
                    required
                    @change="
                      formValidate.id_sublokasi = ''
                     
                    "
                    :class="{
                      'is-valid': form.id_sublokasi != '',
                      'is-invalid':
                        formValidate.id_sublokasi || form.id_sublokasi == '',
                    }"
                  >
                    <option value="" disabled>-Pilih-</option>
                    <option
                      v-for="lokasi in listSubLokasi"
                      :key="lokasi.id_sublokasi"
                      :value="lokasi.id_sublokasi"
                    >
                      {{ lokasi.nama_sublokasi }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- <div class="col-md-3" v-if="form.type_ticket == 'Corrective'">
                <div class="form-group">
                  <label class="f-w-600" for="nama_device">Device </label>
                  <select
                    v-model="form.id_device"
                    type="text"
                    class="form-control"
                    required
                    @change="formValidate.id_device = ''"
                    :class="{
                      'is-valid': form.id_device != '',
                      'is-invalid':
                        formValidate.id_device || form.id_device == '',
                    }"
                  >
                    <option value="" disabled>-Pilih-</option>
                    <option
                      v-for="device in listDevice"
                      :key="device.id_device"
                      :value="device.id_device"
                    >
                      {{ device.nama_device }}
                    </option>
                  </select>
                </div>
              </div> -->
              
              <div class="col-md-12" v-if="form.type_ticket == 'Task List'">
                <div class="form-group">
                  <label class="f-w-600" for="id_customer">Customers</label>
                  <select
                    v-model="form.id_customer"
                    type="text"
                    class="form-control"
                    required
                    @change="formValidate.id_customer = ''"
                    :class="{
                      'is-valid': form.id_customer != '',
                      'is-invalid':
                        formValidate.id_customer || form.id_customer == '',
                    }"
                  >
                    <option value="" disabled>-Pilih-</option>
                    <option
                      v-for="customer in listCustomer"
                      :key="customer.id_customer"
                      :value="customer.id_customer"
                    >
                      {{ customer.nama_customer }} -
                      {{ customer.nama_perusahaan_customer }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="f-w-600" for="id_team">Team</label>
                  <select
                    v-model="form.id_team"
                    type="text"
                    class="form-control"
                    required
                    @change="formValidate.id_team = ''"
                    :class="{
                      'is-valid': form.id_team != '',
                      'is-invalid': formValidate.id_team || form.id_team == '',
                    }"
                  >
                    <option value="" disabled>-Pilih-</option>
                    <option
                      v-for="team in listTeam"
                      :key="team.id_team"
                      :value="team.id_team"
                    >
                      {{ team.nama_team }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="f-w-600" for="id_cat_ticket">Problem</label>
                  <select
                    v-model="form.id_cat_ticket"
                    type="text"
                    class="form-control"
                    required
                    @change="formValidate.id_cat_ticket = ''"
                    :class="{
                      'is-valid': form.id_cat_ticket != '',
                      'is-invalid':
                        formValidate.id_cat_ticket || form.id_cat_ticket == '',
                    }"
                  >
                    <option value="" disabled>-Pilih-</option>
                    <option
                      v-for="problem in listProblem"
                      :key="problem.id_cat_ticket"
                      :value="problem.id_cat_ticket"
                    >
                      {{ problem.cat_ticket }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="f-w-600" for="date_ticket">Issue Date</label>
                  <input
                    v-model="form.issue_date"
                    type="date"
                    class="form-control"
                    placeholder="Select Date"
                    required
                    @input="formValidate.issue_date = ''"
                    :class="{
                      'is-valid': form.issue_date != '',
                      'is-invalid':
                        formValidate.issue_date || form.issue_date == '',
                    }"
                  />
                </div>
              </div>              
              <div class="col-md-6">
                <div class="form-group">
                  <label class="f-w-600" for="subject_ticket">Issue</label>
                  <input
                    v-model="form.subject_ticket"
                    type="text"
                    class="form-control"
                    placeholder="Input Judul Ticket"
                    required
                    @input="formValidate.subject_ticket = ''"
                    :class="{
                      'is-valid': form.subject_ticket != '',
                      'is-invalid':
                        formValidate.subject_ticket ||
                        form.subject_ticket == '',
                    }"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label class="f-w-600" for="fill_ticket">Description</label>
                  <textarea
                    v-model="form.fill_ticket"
                    rows="10"
                    class="form-control"
                    placeholder="Input Isi Ticket"
                    required
                    @input="formValidate.fill_ticket = ''"
                    :class="{
                      'is-valid': form.fill_ticket != '',
                      'is-invalid':
                        formValidate.fill_ticket || form.fill_ticket == '',
                    }"
                  ></textarea>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label class="f-w-600" for="fill_ticket">Attachment</label>
                  <input type="file" name="upload_file"
                  class="form-control"
                  placeholder="Upload File"
                  @change="selectedFiles($event)">
                  
                </div>
              </div>
              <div class="col-md-12">
                <span v-if="file_view !== null"  ><a class="btn btn-sm btn-primary" :href="file_view" target="_blank"><i class="fas fa-file"></i> &nbsp;&nbsp; Open Attachment </a></span>
                </div>
            </div>
          </div>
          <div class="panel-footer">
            <div class="row">
              <div class="col-md-6 mb-2">
                <a
                  href="javascript:window.history.go(-1);"
                  class="btn btn-default"
                  >Kembali</a
                >
                <button v-if="this.userLevel == 'Administrator' || this.userLevel == 'SPV'" @click="showModalProblem" class="btn btn-warning ml-1">
                  Tambah Problem
                </button>

                <!-- <button
                  class="btn btn-info ml-1"
                  @click="postDataResponse"
                  variant="info"
                  :disabled="loading"
                >
                  <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                  Response <span v-if="response_time !=''">{{response_time}}</span>
                </button> -->
              </div>
              <div class="col-md-6">
                
                 
                <button v-if="this.userLevel == 'Administrator' || this.userLevel == 'SPV'"
                  class="btn btn-success float-md-right"
                  @click="postData"
                  variant="primary"
                  :disabled="loading"
                >
                  <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                  Submit
                </button>

                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modal brand -->
    <b-modal v-model="modalVisibleProblem" @hide="beforeModalProblemClose">
      <template #modal-header>
        <h5 class="modal-title">Tambah Problem</h5>
      </template>
      <div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="f-w-600" for="nama_cat_maintenance"
                >Nama Problem
              </label>
              <input
                v-model="problem.cat_ticket"
                type="tel"
                class="form-control"
                placeholder="Input Nama Problem Ticket"
                required
                @input="formValidate.cat_ticket = ''"
                :class="{
                  'is-valid': problem.cat_ticket != '',
                  'is-invalid':
                    formValidate.cat_ticket || problem.cat_ticket == '',
                }"
              />
            </div>
          </div>
          <div class="col-md-12">
            <b-alert v-if="statusProblem" variant="success" show fade>
              <strong>Success!</strong>
              <br />
              <span>{{ problemAction }} Problem Sukses</span>
            </b-alert>
          </div>
          <div class="col-md-12">
            <div style="max-height: 50vh; overflow-y: scroll">
              <table class="table table-bordered table-striped">
                <thead>
                  <tr class="text-center">
                    <th>Nama Type</th>
                    <th style="width: 100px"><i class="fa fa-cogs"></i></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="problem in listProblem"
                    :key="problem.id_cat_ticket"
                  >
                    <td>{{ problem.cat_ticket }}</td>
                    <td class="text-center">
                      <button
                        class="btn btn-icon btn-warning m-r-5"
                        v-on:click="
                          editProblem(problem.id_cat_ticket, problem.cat_ticket)
                        "
                      >
                        <i class="fa fa-pencil-alt"></i>
                      </button>
                      <button
                        class="btn btn-icon btn-danger"
                        v-on:click="confirmDelete(problem.id_type, 'type')"
                      >
                        <i class="fa fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <button
          @click="closeModalProblem"
          class="btn btn-info"
          variant="primary"
        >
          Tutup
        </button>
        <button
          class="btn btn-success"
          @click="postProblem"
          variant="primary"
          :disabled="loadingProblem"
        >
          <i v-if="loadingProblem" class="fas fa-circle-notch fa-spin"></i>
          Submit
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import userData from "../../plugins/userData";

export default {
  mixins: [userData],
  data() {
    return {
      file:null,
      file_view:'',
      response_time:'',
      id_ticket:'',
      form: {
        id_area: "",
        id_lokasi: "",
        id_sublokasi: "",
        id_device: "",
        id_cat_ticket: "",
        id_team: "",
        id_customer: "",
        subject_ticket: "",
        fill_ticket: "",
        type_ticket: "",
        date_ticket: "",
        issue_date: "",
      },
      problem: {
        cat_ticket: "",
      },
      listCustomer: [],
      listTeam: [],
      listArea: [],
      listLokasi: [],
      listSubLokasi: [],
      listDevice: [],
      listProblem: [],
      formValidate: [],
      loading: false,
      loadingProblem: false,
      modalVisibleProblem: false,
      conditionMetBrand: false,
      statusProblem: false,
      loadingType: false,
      modalVisibleType: false,
      conditionMetType: false,
      statusType: false,
      problemAction: "Create",
    };
  },
  mounted() {
    this.getCustomer();
    this.getProblem();
    this.getArea();
    this.getTeam();
    const id = this.$route.params.id;
    this.id_ticket = this.$route.params.id;
    if (id) {
      this.fetchData(id);
    }
  },
  methods: {
    fetchData(id) {
      axios
        .get(this.$url+"/v2/ticket/detail/" + id)
        .then((response) => {
          for (let key in response.data.data) {
            this.form[key] = response.data.data[key];
          }
          if (this.form.id_area) {
            this.getLokasi();
          }
          if (this.form.id_lokasi) {
            this.getSubLokasi();
          }

          this.file_view = response.data.data.file;

        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getCustomer() {
      axios
        .get(this.$url+"/v2/customer/option/")
        .then((response) => {
          this.listCustomer = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getTeam() {
      axios
        .get(this.$url+"/v2/team/option/")
        .then((response) => {
          this.listTeam = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getArea() {
      axios
        .get(this.$url+"/v2/area/option/")
        .then((response) => {
          this.listArea = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getLokasi() {
      if (this.form.id_area) {
        axios
          .get(this.$url+"/v2/lokasi/option/" + this.form.id_area)
          .then((response) => {
            this.listLokasi = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      }
    },
    getSubLokasi() {
      if (this.form.id_lokasi) {
        axios
          .get(this.$url+"/v2/sublokasi/option/" + this.form.id_lokasi)
          .then((response) => {
            this.listSubLokasi = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      }
    },
    getDevice() {
      if (this.form.id_sublokasi) {
        axios
          .get(this.$url+"/v2/device/option/" + this.form.id_sublokasi)
          .then((response) => {
            this.listDevice = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      }
    },
    getProblem() {
      this.listProblem = [];
      axios
        .get(this.$url+"/v2/kategori_ticket/")
        .then((response) => {
          this.listProblem = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },selectedFiles(event){
      console.log(event)
      this.file = event.target.files[0];

    }
    ,
    postData() {
      this.loading = true;
      const formData = new FormData();
      if(this.file != null){
        formData.append('file', this.file);
      }
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
      axios
        .post(this.$url+"/v2/ticket/update/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Input form tidak lengkap !",
              });
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.$router.go(-1);
              }
            }, 700);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.error = error.message;
        });
    },
    postProblem() {
      this.loadingProblem = true;
      const formData = new FormData();
      for (let key in this.problem) {
        formData.append(key, this.problem[key]);
      }
      axios
        .post(`${this.$url}/v2/kategori_ticket/${this.problemAction}/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loadingProblem = false;
            this.formValidate = response.data.message;
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Input form tidak lengkap !",
              });
          } else {
            this.loadingProblem = false;
            this.statusProblem = true;
            setTimeout(() => {
              this.statusProblem = false;
              this.problem.id_cat_ticket = "";
              this.problem.cat_ticket = "";
              this.problemAction = "Create";
              this.closeModalProblem();
              this.getProblem();
            }, 1000);
          }
        })
        .catch((error) => {
          this.loadingProblem = false;
          console.log(error);
          this.error = error.message;
        });
    },
    postDataResponse() {
      this.loading = true;
      const formData = new FormData();
        formData.append('id_ticket', this.$route.params.id);
      axios
        .post(`${this.$url}/v2/ticket/response/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loadingProblem = false;
            this.formValidate = response.data.message;
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Input form tidak lengkap !",
              });
          } else {
            this.loadingProblem = false;
            this.statusProblem = true;
             this.response_time = response.data;

          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.error = error.message;
        });
    },
    editProblem(id_cat_ticket, cat_ticket) {
      this.problemAction = "Update";
      this.problem.id_cat_ticket = id_cat_ticket;
      this.problem.cat_ticket = cat_ticket;
    },
    showModalProblem() {
      this.modalVisibleProblem = true;
      this.conditionMetBrand = false;
    },
    closeModalProblem() {
      this.modalVisibleProblem = false;
      this.conditionMetBrand = true;
    },
    beforeModalProblemClose(evt) {
      if (!this.conditionMetBrand) {
        evt.preventDefault();
      }
    },
  },
};
</script>